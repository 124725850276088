import * as images from "../assets";

export const languages = [
  {
    image: images.python,
    languageName: "Python",
    knownledgeLevel: 80,
    type: "Linguagem",
    professional: "Sim",
    professionalTime: "4 anos",
    educational: "Sim",
    educationalTime: "4 anos",
  },
  {
    image: images.typescript,
    languageName: "Typescript",
    knownledgeLevel: 70,
    type: "Linguagem",
    professional: "Sim",
    professionalTime: "4 anos",
    educational: "Sim",
    educationalTime: "4 anos",
  },
  {
    image: images.javascript,
    languageName: "Javascript",
    knownledgeLevel: 80,
    type: "Linguagem",
    professional: "Sim",
    professionalTime: "4 anos",
    educational: "Sim",
    educationalTime: "6 anos",
  },
  {
    image: images.java,
    languageName: "Java",
    knownledgeLevel: 50,
    type: "Linguagem",
    professional: "Não",
    professionalTime: "--",
    educational: "Sim",
    educationalTime: "3 anos",
  },
  {
    image: images.php,
    languageName: "PHP",
    knownledgeLevel: 50,
    type: "Linguagem",
    professional: "Não",
    professionalTime: "--",
    educational: "Sim",
    educationalTime: "2 anos",
  },
  {
    image: images.react,
    languageName: "React",
    knownledgeLevel: 85,
    type: "Framework",
    professional: "Sim",
    professionalTime: "4 anos",
    educational: "Sim",
    educationalTime: "4 anos",
  },
  {
    image: images.next,
    languageName: "Next JS",
    knownledgeLevel: 70,
    type: "Framework",
    professional: "Sim",
    professionalTime: "1 ano",
    educational: "Sim",
    educationalTime: "1 ano",
  },
  {
    image: images.angular,
    languageName: "Angular",
    knownledgeLevel: 60,
    type: "Framework",
    professional: "Não",
    professionalTime: "--",
    educational: "Sim",
    educationalTime: "1 ano",
  },
  {
    image: images.django,
    languageName: "Django",
    knownledgeLevel: 70,
    type: "Framework",
    professional: "Sim",
    professionalTime: "2 anos",
    educational: "Sim",
    educationalTime: "2 anos",
  },
  {
    image: images.node,
    languageName: "Node JS",
    knownledgeLevel: 80,
    type: "Framework",
    professional: "Sim",
    professionalTime: "4 anos",
    educational: "Sim",
    educationalTime: "4 anos",
  },
  {
    image: images.nest,
    languageName: "Nest JS",
    knownledgeLevel: 70,
    type: "Framework",
    professional: "Sim",
    professionalTime: "2 anos",
    educational: "Sim",
    educationalTime: "2 anos",
  },
  {
    image: images.sqlServer,
    languageName: "SQL Server",
    knownledgeLevel: 63,
    type: "SGBD",
    professional: "Sim",
    professionalTime: "2 anos",
    educational: "Sim",
    educationalTime: "2 anos",
  },
  {
    image: images.postgres,
    languageName: "PostgreSQL",
    knownledgeLevel: 70,
    type: "SGBD",
    professional: "Sim",
    professionalTime: "4 anos",
    educational: "Sim",
    educationalTime: "4 anos",
  },
  {
    image: images.mysql,
    languageName: "MySQL",
    knownledgeLevel: 60,
    type: "SGBD",
    professional: "Sim",
    professionalTime: "4 anos",
    educational: "Sim",
    educationalTime: "4 anos",
  },
];
