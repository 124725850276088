import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "./Header";
import Section from "./Section";
import { mensagem } from "../assets/texto";
import nickolas from "../assets/foto-boa.jpeg";
import LanguageContainer from "../components/LanguageContainer";
import { languages } from "../utils/data";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import ConstructionIcon from "@mui/icons-material/Construction";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import { scroller } from "react-scroll";
import ExperiencesContainer from "./ExperiencesContainer";
import { experiences } from "../utils/experiencesData";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ComputerIcon from "@mui/icons-material/Computer";
import logo from "../assets/logo.png";
import HeaderMobile from "./mobile/HeaderMobile";

const Main = ({ className }) => {
  const [texto, setTexto] = useState("");
  const [tab, setTab] = useState(false);
  const [textComplete, setTextComplete] = useState(false);
  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index === mensagem.length) {
        clearInterval(interval);
        setTextComplete(true);
        return;
      } else if (!textComplete) {
        setTexto((prevTexto) =>
          mensagem[index] ? prevTexto + mensagem[index] : prevTexto
        );
        index++;
      }
    }, 25);

    if (!textComplete) {
      return () => {
        clearInterval(interval);
      };
    } else {
      return;
    }
  }, [textComplete]);
  window.addEventListener("scroll", function () {
    if (window.scrollY > 95 && tab === false) {
      setTab(true);
    } else if (window.scrollY <= 95 && tab === true) {
      setTab(false);
    }
  });

  const scrollToSection = (id) => {
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  return (
    <div className={className} id="main">
      <Header />
      {/* <HeaderMobile /> */}
      <div
        className="icons"
        style={tab ? { display: "flex" } : { display: "none" }}
      >
        <Tooltip title="Home">
          <HomeIcon onClick={() => scrollToSection("main")} />
        </Tooltip>
        <Tooltip title="Portifolio">
          <FolderSharedIcon onClick={() => scrollToSection("portifolio")} />
        </Tooltip>
        <Tooltip title="Cases">
          <ConstructionIcon onClick={() => scrollToSection("experiences")} />
        </Tooltip>
        <Tooltip title="Contatos">
          <InfoIcon onClick={() => scrollToSection("contacts")} />
        </Tooltip>
      </div>
      <div className="sections">
        <Section id="home">
          <img src={nickolas} alt="Nickolas Muzzi" />
          <div className="text">
            <h1>Seja Bem Vindo!</h1>
            <h4>{texto}</h4>
          </div>
        </Section>
        <Section id="portifolio">
          <h1 style={{ textAlign: "center" }}>Meu Portifolio</h1>
          <h4 style={{ textAlign: "center" }}>
            Aqui você verá as linguagens e frameworks com que já trabalhei e o
            nivel de conhecimento que tenho em cada uma delas.
          </h4>
          <div className="container-languages">
            <LanguageContainer data={languages} />
          </div>
        </Section>
        <Section id="experiences">
          <h1 style={{ textAlign: "center" }}>Experiências</h1>
          <h4 style={{ textAlign: "center" }}>
            Aqui você verá as experiências de mercado que eu tenho em meu
            curriculo.
          </h4>
          <div className="container-languages">
            <ExperiencesContainer data={experiences} />
          </div>
        </Section>
        <Section id="contacts" height="70vh">
          <h1 style={{ textAlign: "center" }}>Entre em contato comigo</h1>
          <h4 style={{ textAlign: "center" }}>
            Atualmente além de trabalhar como Analista de Sistemas no regime
            CLT, também recebo propostas de projetos para Freelance. Então, caso
            seja do seu interesse, você pode entrar em contato comigo, através
            dos seguintes meios de comunicação:
          </h4>
          <div className="container-languages">
            <div className="contacts-list">
              <div className="grid-item">
                <InstagramIcon />
                &nbsp;
                <a
                  href="https://www.instagram.com/nickolasmuzzii?igsh=bzV3YzA0bGNpd2Vu&utm_source=qr"
                  target="_blank"
                  rel="noreferrer"
                >{`Siga Nickolas Muzzi no Instagram`}</a>
              </div>
              <div className="grid-item">
                <LinkedInIcon />
                &nbsp;
                <a
                  href="https://www.linkedin.com/in/nickolas-muzzi/"
                  target="_blank"
                  rel="noreferrer"
                >{`Siga Nickolas Muzzi no LinkedIn`}</a>
              </div>
              <div className="grid-item">
                <WhatsAppIcon />
                &nbsp;
                <a
                  href="https://wa.me/31984017410"
                  target="_blank"
                  rel="noreferrer"
                >{`Entre em contato via WhatsApp`}</a>
              </div>
              <div className="grid-item">
                <EmailIcon />
                &nbsp;
                <a
                  href="mailto:nickolasmuzziv@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >{`Entre em contato por email`}</a>
              </div>
            </div>
          </div>
        </Section>
      </div>
      <footer>
        <ComputerIcon />
        &nbsp;Developed By <img src={logo} alt="Nickolas Muzzi" />
      </footer>
    </div>
  );
};

export default styled(Main)`
  height: 100%;
  display: flex;
  flex-direction: column;
  .icons {
    position: fixed;
    top: 30%;
    left: 5px;
    padding: 5px;
    border-radius: 12px;
    bottom: 50%;
    height: 150px;
    font-size: 10px;
    background-color: #99999958;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    background: rgba(255, 255, 255, 0.151);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    a {
      text-decoration: none;
      color: white;
    }
    svg {
      cursor: pointer;
    }
  }
  .sections {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1 {
      font-weight: 300;
      font-size: 48px;
      color: white;
    }
    #home {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      img {
        height: 60%;
        border-radius: 6px;
        /* -webkit-box-shadow: 1px 1px 5px 0px rgba(219, 219, 219, 1);
        -moz-box-shadow: 1px 1px 5px 0px rgba(219, 219, 219, 1);
        box-shadow: -15px -15px 0px 0px #ffffff; */
      }
      .text {
        width: 61%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h4 {
          margin-top: 10px;
          width: 55%;
          font-size: large;
          min-height: 135px;
          color: white;
          text-align: justify;
          font-weight: 100;
          line-height: 1.5;
        }
      }
    }
    #portifolio {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      flex-direction: column;
      h4 {
        width: 55%;
        color: white;
        text-align: center;
        font-weight: 100;
        margin-bottom: 20px;
      }
      .container-languages {
        width: 100%;
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }
    }
    #experiences {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      flex-direction: column;
      h4 {
        width: 55%;
        color: white;
        text-align: center;
        font-weight: 100;
        margin-bottom: 20px;
      }
      .container-languages {
        width: 100%;
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }
    }
    #contacts {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      flex-direction: column;
      h4 {
        width: 55%;
        color: white;
        text-align: center;
        font-weight: 100;
        margin-bottom: 20px;
      }
      .container-languages {
        width: 100%;
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }
      .contacts-list {
        width: 50%;
        height: 50%;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 50px 50px 50px;
        .grid-item {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 40px;
          }
          a {
            cursor: pointer;
            text-decoration: none;
            color: white;
            &:hover {
              color: #a8a8a8;
            }
          }
        }
      }
    }
  }
  footer {
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
  }
`;
