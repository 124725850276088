import React from "react";
import styled from "styled-components";

const Section = ({ className, children, id, height }) => {
  return (
    <section id={id} className={className}>
      {children}
    </section>
  );
};

export default styled(Section)`
  width: 95%;
  height: ${(props) => (props.height ? props.height : "95vh")};
  @media screen and (min-width: 1366px) {
    padding: 60px 20px;
  }
`;
