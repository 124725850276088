import React from "react";
import styled from "styled-components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const LanguageContainer = ({ className, data }) => {
  const [step, setStep] = React.useState(0);

  const changeStep = (direction) => {
    if (step === 0 && direction === "back") {
      return setStep(data.length - 1);
    } else if (step === data.length - 1 && direction === "forward") {
      return setStep(0);
    } else if (direction === "back") {
      return setStep(step - 1);
    } else if (direction === "forward") {
      return setStep(step + 1);
    }
  };
  return (
    <div className={className}>
      <div className="image-container">
        <div className="div-image">
          <img src={data[step].image} alt={data[step].languageName} />
        </div>
        <div className="progress-div">
          <label
            style={{ width: "400px", textAlign: "left" }}
            htmlFor="progress"
          >
            Nível de conhecimento:
          </label>
          <progress
            id="progress"
            value={data[step].knownledgeLevel}
            max={100}
          />
          <div className="description">
            <span>
              {data[step].type}: {data[step].languageName}
            </span>
            <p>
              Experiência profissional: <b>{data[step].professional}</b>
            </p>
            <p>
              Tempo de Experiência: <b>{data[step].professionalTime}</b>
            </p>
            <p>
              Experiência Acadêmica: <b>{data[step].educational}</b>
            </p>
            <p>
              Tempo de Experiência Acadêmica:{" "}
              <b>{data[step].educationalTime}</b>
            </p>
          </div>
        </div>
      </div>
      <div className="arrow-counter">
        <ArrowBackIosNewIcon
          className="icon-1"
          onClick={() => changeStep("back")}
        />
        <div className="counter">
          {step + 1} de {data.length}
        </div>
        <ArrowForwardIosIcon
          className="icon-2"
          onClick={() => changeStep("forward")}
        />
      </div>
    </div>
  );
};

export default styled(LanguageContainer)`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .image-container {
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .div-image {
      min-height: 240px;
      height: 250px;
      img {
        height: 100%;
        margin-bottom: 15px;
      }
    }
    .progress-div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 90%;
      progress {
        margin-top: 5px;
        height: 5px;
        width: 400px;
        background-color: transparent;
        &::-webkit-progress-bar {
          background-color: #99999958; /* Cor de fundo da barra de progresso */
        }
        &::-webkit-progress-value {
          background-color: #00d6b4; /* Cor da barra de progresso preenchida */
        }
      }
      .description {
        padding: 8px 0px;
        width: 70%;
        height: 50%;
        text-align: center;
        span {
          width: 100%;
          text-align: center;
        }
        p {
          width: 100%;
          text-align: left;
          margin: 8px 0px;
        }
      }
    }
  }
  .arrow-counter {
    width: 70%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    .icon-1 {
      cursor: pointer;
      &:active {
        color: #00d6b4;
      }
    }
    .icon-2 {
      cursor: pointer;
      &:active {
        color: #00d6b4;
      }
    }
  }
`;
