import React, { useState } from "react";
import styled from "styled-components";
import Link from "./Link";
import logo from "../assets/logo.png";
import { scroller } from "react-scroll";

const Header = ({ className }) => {
  const [actual, setActual] = useState(1);
  const scrollToSection = (id, actual) => {
    setActual(actual);
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <header className={className}>
      <div className="logo">
        <img src={logo} alt="Nickolas Muzzi" />
      </div>
      <div className="links">
        <Link
          onClick={() => scrollToSection("home", 1)}
          label="Home"
          actual={actual === 1}
        />
        <Link
          onClick={() => scrollToSection("portifolio", 2)}
          label="Portifolio"
          actual={actual === 2}
        />
        <Link
          onClick={() => scrollToSection("experiences", 3)}
          label="Experiências"
          actual={actual === 3}
        />
        <Link
          onClick={() => scrollToSection("contacts", 4)}
          label="Contato"
          actual={actual === 4}
        />
      </div>
    </header>
  );
};

export default styled(Header)`
  padding: 12px;
  height: 10%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.05);

  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  .links {
    width: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-weight: 600;
  }
`;
