import React from 'react';
import styled from "styled-components"

const Link = ({className, label, actual, onClick}) => {

    return (
      <span onClick={() => onClick && onClick()} className={className} >
        {label}
      </span>
    );
}

export default styled(Link)`
  color: ${(props) => props.actual === true ? "#00d6b4" : "#a8a8a8"};
  text-decoration: ${(props) => props.actual === true ? "underline" : "none"};
  cursor: pointer;
`;
