import './App.css';
import Main from "./components/Main"
import { SpeedInsights } from "@vercel/speed-insights/react";

function App() {
  return (
    <div className="App">
      <Main/>
      <SpeedInsights/>
    </div>
  );
}

export default App;
