import * as images from "../assets";

export const experiences = [
  {
    experienceName: "EDUCAT Tecnologia",
    image: images.educat,
    description: `Comecei como estagiário em agosto de 2021, fui efetivado em maio de 2022
      e saí em maio de 2023 como líder técnico do sistema de emissão de diplomas digitais.
      Lá também tive a oportunidade de trabalhar com os outros sistemas da empresa como o
      ERP, Exams, Educat, etc. Desenvolvi grandes habilidades lidando com sistemas de
      assinaturas digitais além de habilidades como lider técnico, Scrum Master, P.O e suporte técnico.
      Hoje tenho grande facilidade para lidar com geração de documentos digitais usando python,
      assinaturas digitais, sistemas de integração, sistemas de gestão entre outros tipos de sistemas.
      `,
    professionalTime: "2 anos",
  },
  {
    experienceName: "DevRise LTDA",
    image: images.devrise,
    description: `Em maio de 2023 tive a oportunidade de abrir minha própria empresa de
    desenvolvimento de software, onde fiquei cerca de 1 ano trabalhando com alguns clientes
    mas dentre eles, principalmente um sistema de emissão de laudos digitais. Lidamos com
    arquiteturas de domínio e micro-serviços, projetos de grande escala e alta complexidade.
    No início de 2024, resolvi buscar vagas de emprego como CLT por achar que regime PJ
    fosse pouco seguro tendo em vista o momento da minha carreira.`,
    professionalTime: "1 ano",
  },
  {
    experienceName: "Fundação Educacional Lucas Machado - FELUMA",
    image: images.feluma,
    description: `Em março de 2024 consegui entrar na FELUMA através de um processo seletivo,
    como analista de sistemas e estou trabalhando aqui até o presente momento. Na FELUMA trabalho
    principalmente com Django (Um framework Python para REST API's) e React. Utilizando esses
    frameworks, administro o sistema de gestão academica da faculdade Ciências Médicas de Minas
    Gerais.
    `,
    professionalTime: "Março de 2024 - Presente",
  },
];
